/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  $.fn.serializeArrayAll = function () {
    var rCRLF = /\r?\n/g;
    return this.map(function () {
      return this.elements ? jQuery.makeArray(this.elements) : this;
    })
      .map(function (i, elem) {
        var val = jQuery(this).val();
        if (val == null) {
          return val == null;
          //next 2 lines of code look if it is a checkbox and set the value to blank
          //if it is unchecked
        } else if (this.type === "checkbox" && this.checked === false) {
          return { name: this.name, value: this.checked ? this.value : "" };
          //next lines are kept from default jQuery implementation and
          //default to all checkboxes = on
        } else {
          return jQuery.isArray(val) ? jQuery.map(val, function (val, i) {
                return { name: elem.name, value: val.replace(rCRLF, "\r\n") };
              })
            : { name: elem.name, value: val.replace(rCRLF, "\r\n") };
        }
      })
      .get();
  };

  $.fn.inView = function (inViewType) {
    //https://github.com/Mario-Duarte/inView

    //Calculates the viewport top and bottom position
    //  at the time of the call
    var offset = 0;
    var viewport = {};
    viewport.top = $(window).scrollTop();
    viewport.bottom = viewport.top + $(window).height();

    // Calculate element bouds positions
    var bounds = {};
    bounds.top = this.offset().top + offset;
    bounds.bottom = bounds.top + this.outerHeight();

    switch (inViewType) {
      case "bottomOnly":
        return (
          bounds.bottom <= viewport.bottom && bounds.bottom >= viewport.top
        );
      case "topOnly":
        return bounds.top <= viewport.bottom && bounds.top >= viewport.top;
      case "both":
        return bounds.top >= viewport.top && bounds.bottom <= viewport.bottom;
      default:
        // return ((bounds.top >= viewport.top) && (bounds.bottom <= viewport.bottom));
        return (
          bounds.bottom <= viewport.bottom && bounds.bottom >= viewport.top
        );
    }
  };

  var Fn = {
    videoPlayer: function () {
      var videos = $(".block-video"),
        allVideos = [];
      if (videos.length > 0) {
        videos.each(function (index) {
          allVideos[index] = new Plyr($(this).find(".js-player"), {
            muted: true,
          });
        });
        videos.bind("inview", function (event, visible) {
          var index = $(this).index(".block-video");
          if (visible) {
            allVideos[index].play();
          } else {
            allVideos[index].pause();
          }
        });
      }
      // var player =  new Plyr($('.js-player'));
      // plyr.setup('.plyr__video-embed', {
      //   autoplay: true,
      //   controls: []
      // });
    },
    search: function () {
      var body = $("body");
      $(".open-search").click(function (e) {
        e.preventDefault();
        body.addClass("search-active");
      });
      $(".close-search").click(function (e) {
        e.preventDefault();
        body.removeClass("search-active");
      });
    },
    fakeShare: function () {
      $(".social .social-items a").click(function (e) {
        e.preventDefault();
        var $link = $(this);
        var href = $link.attr("href");
        var network = $link.attr("data-network");

        var networks = {
          facebook: { width: 600, height: 300 },
          twitter: { width: 600, height: 254 },
          google: { width: 515, height: 490 },
          linkedin: { width: 600, height: 473 },
          pinterest: { width: 600, height: 600 },
        };

        var x = screen.width / 2 - networks[network].width / 2;
        var y = screen.height / 2 - networks[network].height / 2 - 200;

        var popup = function (network) {
          var options = "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,";
          window.open(
            href,
            "",
            options +
              "height=" +
              networks[network].height +
              ",width=" +
              networks[network].width +
              ",left=" +
              x +
              ",top=" +
              y
          );
        };

        popup(network);
      });
    },
    download_file: function (fileURL, fileName) {
      // for non-IE
      if (!window.ActiveXObject) {
        var save = document.createElement("a");
        save.href = fileURL;
        save.target = "_blank";
        var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
        save.download = fileName || filename;
        if (
          navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
          navigator.userAgent.search("Chrome") < 0
        ) {
          document.location = save.href;
          // window event not working here
        } else {
          var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      }

      // for IE < 11
      else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, "_blank");
        _window.document.close();
        _window.document.execCommand("SaveAs", true, fileName || fileURL);
        _window.close();
      }
    },
    lastWord: function () {
      $(".title-curved .title").each(function (index, element) {
        var heading = $(element);
        var Word_array, last_Word, first_part;

        Word_array = heading.html().split(/\s+/); // split on spaces
        last_Word = Word_array.pop(); // pop the last Word
        first_part = Word_array.join(" "); // rejoin the first words together

        heading.html(
          [
            first_part,
            ' <span class="last-word d-block bold">',
            last_Word,
            "</span>",
          ].join("")
        );
      });
    },
    moduleFullWidth: function () {
      $(".img-to-scale").on("inview", function (event, isInView) {
        var _this = $(this);
        if (isInView) {
          _this.addClass("active");
        } else {
          _this.removeClass("active");
        }
      });
    },
    socialShare: function () {
      $(".social .share-text").click(function (e) {
        e.preventDefault();
        var _this = $(this),
          socialItem = $(".social .social-items");

        if (_this.hasClass("active")) {
          socialItem.hide();
        } else {
          socialItem.show();
        }
        _this.toggleClass("active");
      });
    },
    checkSitckyContent: function () {
      // Enable sticky column on single post sidebar
      var leftCol = $(".left-col"),
        rightCol = $(".right-col").find(".make-me-sticky"),
        sidebar = $(".sidebar-ref");

      if (leftCol.height() < rightCol.height()) {
        sidebar.addClass("no-sticky");
      }
    },
    fixedFilters: function () {
      var fFilters = $(".fixed-filters"),
        distance = fFilters.offset().top,
        body = $("body"),
        listing = $(".listing"),
        filterHeight = fFilters.outerHeight() + 40;

      $(window).scroll(function () {
        // if ( $(this).scrollTop()+$('header.minified').outerHeight()+20 >= distance ) {
        if ($(this).scrollTop() + 20 >= distance) {
          body.addClass("locked-filters");
          if ($(window).width() >= 992) {
            listing.css("padding-top", filterHeight + "px");
          }
        } else {
          body.removeClass("locked-filters");
          listing.removeAttr("style");
        }
      });
    },
    woocommerce_ajax_add_to_cart: function () {
      //Ajax add to cart
      $(document).on(
        "click",
        ".single_add_to_cart_button:not(.disabled)",
        function (e) {
          var $thisbutton = $(this),
            $form = $thisbutton.closest("form.cart"),
            //quantity = $form.find('input[name=quantity]').val() || 1,
            //product_id = $form.find('input[name=variation_id]').val() || $thisbutton.val(),
            data =
              $form
                .find(
                  'input:not([name="product_id"]), select, button, textarea'
                )
                .serializeArrayAll() || 0;

          $.each(data, function (i, item) {
            if (item.name === "add-to-cart") {
              item.name = "product_id";
              item.value =
                $form.find("input[name=variation_id]").val() ||
                $thisbutton.val();
            }
          });

          e.preventDefault();

          $(document.body).trigger("adding_to_cart", [$thisbutton, data]);

          $.ajax({
            type: "POST",
            url: woocommerce_params.wc_ajax_url
              .toString()
              .replace("%%endpoint%%", "add_to_cart"),
            data: data,
            beforeSend: function (response) {
              $thisbutton.removeClass("added").addClass("loading");
            },
            complete: function (response) {
              $thisbutton.addClass("added").removeClass("loading");
            },
            success: function (response) {
              if (response.error && response.product_url) {
                window.location = response.product_url;
                return;
              }

              $(document.body).trigger("added_to_cart", [
                response.fragments,
                response.cart_hash,
                $thisbutton,
              ]);
            },
          });

          return false;
        }
      );
    },
    menuMask: function () {
      $("header.header .has-megamenu").each(function () {
        $(this)
          .mouseenter(function () {
            $(".mask").stop().fadeIn(300);
          })
          .mouseleave(function () {
            $(".mask").stop().fadeOut(300);
          });
      });
    },
  };

  var Brands = {
    init: function() {
      if(document.body.classList.contains("nos-vins")) {
        var brands = Array.from(document.querySelectorAll(".brand-item .wine-name"));
        var brandsItem = Array.from(document.querySelectorAll(".brand-item"));
        var brandsContent = Array.from(document.querySelectorAll(".brands-content .brands-detail"));

        brands.forEach(function(brand) {
          brand.addEventListener("click", function() {
            brandsItem.forEach(function(item) {
              item.classList.remove("is-active");
            });

            brand.parentElement.classList.add("is-active");

            brandsContent.forEach(function(content) {
              content.classList.remove("show");
            });
          });
        });
      }
    }
  };

  var Castles = {
    init: function() {
      if(document.body.classList.contains("nos-vins") || document.body.classList.contains("nos-vins-casher") || document.body.classList.contains("page-id-9281") || document.body.classList.contains("our-kosher-wines")) {
        var castles = Array.from(document.querySelectorAll(".castle-item .wine-name"));
        var castlesItem = Array.from(document.querySelectorAll(".castle-item"));
        var castlesContent = Array.from(document.querySelectorAll(".castles-content .castles-detail"));

        castlesContent.forEach(function(content, index){
          if(index > 0) {
            content.classList.remove("show");
          }
        });
        castlesItem[0].click();

        castles.forEach(function(castle) {
          castle.addEventListener("click", function() {
            castlesItem.forEach(function(item, index) {
              item.classList.remove("is-active");
            });

            castle.parentElement.classList.add("is-active");

            castlesContent.forEach(function(content) {
              content.classList.remove("show");
            });
          });
        });
      }
    }
  };

  var Popup = {
    init: function () {
      // Close popin promo (on background click)
      $(document).on("click", ".popin-promo-background", function (e) {
        e.preventDefault();
        $("#promo-popin").removeClass("active");
      });
      $(document).on("click", ".closePopin", function (e) {
        e.preventDefault();
        $("#promo-popin").removeClass("active");
        var date = new Date(),
          expires = "expires=",
          name = "promoseen",
          value = "yes";
        date.setDate(date.getDate() + 1);
        expires += date.toGMTString();
        document.cookie = name + "=" + value + "; " + expires + "; path=/";
      });
      $(document).on("click", "#promo-button", function (e) {
        $("#promo-popin").removeClass("active");
        var date = new Date(),
          expires = "expires=",
          name = "promoseen",
          value = "yes";
        date.setDate(date.getDate() + 1);
        expires += date.toGMTString();
        document.cookie = name + "=" + value + "; " + expires + "; path=/";
      });
    },
  };

  var PopupShop = {
    init: function () {
      // Close popin promo fidelity (on background click)
      $(document).on(
        "click",
        ".popin-fidelity-program-background",
        function (e) {
          e.preventDefault();
          $("#promo-popin-fidelity").removeClass("active");
        }
      );

      $(document).on("click", ".closePopin", function (e) {
        e.preventDefault();
        $("#promo-popin-fidelity").removeClass("active");
        var date = new Date(),
          expires = "expires=",
          name = "promo-fidelity-seen",
          value = "yes";
        date.setDate(date.getDate() + 1);
        expires += date.toGMTString();
        document.cookie = name + "=" + value + "; " + expires + "; path=/";
      });
    },
  };

  var PopupChristmas = {
    init: function () {
      $(document).on("click", ".popin-christmas-home-background", function (e) {
        e.preventDefault();
        $("#promo-popin-christmas-home").removeClass("active");
      });

      $(document).on("click", ".popin-christmas-home-container", function (e) {
        if($(e.target).attr("class") === "popin-christmas-home-container") {
          e.preventDefault();
          $("#promo-popin-christmas-home").removeClass("active");
        }
      });

      $(document).on("click", "#promo-christmas-button", function (e) {
        $("#promo-popin").removeClass("active");
        var date = new Date(),
          expires = "expires=",
          name = "promo-christmas-seen",
          value = "yes";
        // date.setDate(date.getDate() + 1);
        // expires += date.toGMTString();
        var inOneHour = new Date(date.getTime() + 60 * 60 * 1000); // 1 Hour
        expires += inOneHour;
        document.cookie = name + "=" + value + "; " + expires + "; path=/";
      });
    },
  };

  // var PopupChristmasShop = {
  //   init: function () {
  //     $(document).on("click", ".popin-christmas-shop-background", function (e) {
  //       e.preventDefault();
  //       $("#promo-popin-christmas-shop").removeClass("active");
  //     });

  //     $(document).on("click", ".popin-christmas-shop-container", function (e) {
  //       if($(e.target).attr("class") === "popin-christmas-shop-container") {
  //         e.preventDefault();
  //         $("#promo-popin-christmas-shop").removeClass("active");
  //       }
  //     });

  //     $(document).on("click", "#promo-christmas-shop-button", function (e) {
  //       $("#promo-popin").removeClass("active");
  //       var date = new Date(),
  //         expires = "expires=",
  //         name = "promo-christmas-shop-seen",
  //         value = "yes";
  //       // date.setDate(date.getDate() + 1);
  //       // expires += date.toGMTString();
  //       var inOneHour = new Date(date.getTime() + 60 * 60 * 1000); // 1 Hour
  //       expires += inOneHour;
  //       document.cookie = name + "=" + value + "; " + expires + "; path=/";
  //     });
  //   },
  // };

  var Ajax = {
    init: function () {
      // Generic 'consent' checkbox.
      $(document).on("click", ".box-consent", function (e) {
        var form = $(this).closest("form"),
          submit = form.find(".submit");
        if ($(this).prop("checked")) {
          submit.removeClass("no-consent");
          form.find(".custom-checkbox").removeClass("remind-custom-consent");
        } else {
          submit.addClass("no-consent");
        }
      });

      // Submit newsletter.
      $(document).on("click", ".newsletter-form .submit", function (e) {
        // .block-newsletter
        e.preventDefault();
        var form = $(this).parents(".newsletter-form"),
          email = form.find("#newsletter-email").val(),
          _messageDiv = $(".newsletter .message");
        _messageDiv.empty().removeClass("error success").show();
        if (
          email !== "" &&
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
        ) {
          if (!$(this).hasClass("no-consent")) {
            Ajax.subscribeNewsletter(email, form);
          } else {
            // form.find('.custom-checkbox').addClass('remind-custom-consent');
            _messageDiv
              .text(Config.js_translations.newsletter_please_consent)
              .addClass("error");
          }
        } else {
          _messageDiv
            .text(Config.js_translations.newsletter_invalid_email)
            .addClass("error");
        }
      });

      $(document).on("click", ".articles-loadmore", function (e) {
        e.preventDefault();
        var _this = $(this);
        Ajax.loadMore(_this, "loadmoreArticles", ".article-results", _this);
      });
    },
    subscribeNewsletter: function (email, form) {
      var loader = $(".btn-subscribe"),
        data = {
          action: "subscribeNewsletter",
          email: email,
          list: form.find("input[name='list']:checked").val(),
        };

      $.ajax({
        url: Config.ajax_url,
        data: data,
        type: "POST",
        beforeSend: function (xhr) {
          loader.addClass("loading");
        },
        success: function (response) {
          var _message = Config.js_translations.mailchimp_404,
            _messageDiv = $(".newsletter .message");
          _messageDiv.empty().removeClass("error success").show();
          if (response === "200" || response === "202") {
            _message = response === "200" ? Config.js_translations.mailchimp_200 : Config.js_translations.mailchimp_202;
            _messageDiv.text(_message);
            if (response === "200") {
              _messageDiv.addClass("success");
              $(".newsletter-form #newsletter-email").val("");
              $(".box-consent").prop("checked", false);
              setTimeout(function () {
                _messageDiv.slideUp("slow");
              }, 5000);
            } else {
              _messageDiv.addClass("error");
            }
          } else {
            _messageDiv.text(_message).text(_message).addClass("error");
          }
          _messageDiv.removeClass("d-none");
          loader.removeClass("loading");
        },
      }); // end Ajax
    },
    loadMore: function (button, ajaxAction, listID, loader) {
      var currentPage = button.attr("data-current-page"),
        maxNumPages = button.attr("data-max-num-pages"),
        exclude = button.attr("data-exclude"),
        categories = button.attr("data-categories"),
        data = {
          action: ajaxAction,
          current_page: currentPage,
          exclude: exclude,
          categories: categories,
          is_ajax: true,
        };

      button.attr("disabled", "disabled");

      $.ajax({
        url: Config.ajax_url,
        data: data,
        type: "POST",
        beforeSend: function (xhr) {
          loader.addClass("active");
        },
        success: function (data) {
          if (data) {
            $(listID).append(data);

            currentPage = parseInt(currentPage) + 1;
            button.attr("data-current-page", currentPage);

            button.removeAttr("disabled");
            loader.removeClass("active");

            if (currentPage >= maxNumPages) {
              button.remove();
            }
          } else {
            button.remove(); // if no data, remove the button as well
          }
        },
      }); // end Ajax
    },
  };

  var Slider = {
    create: function (id, parameters) {
      var _hasParameters = typeof parameters !== "undefined",
        _sliderParameters = {
          speed: 800,
          navigation: {
            nextEl: id + " .swiper-button-next",
            prevEl: id + " .swiper-button-prev",
          },
          pagination: {
            el: id + " .swiper-pagination",
            // dynamicBullets: true,
            clickable: true,
          },
          watchOverflow: true,
          watchSlidesVisibility: true,
          autoplay: {
            delay: 5000,
          },
          spaceBetween: 30,
        };

      if (_hasParameters) {
        if (typeof parameters.slidesPerView !== "undefined") {
          _sliderParameters.slidesPerView = parameters.slidesPerView;
        }
        if (typeof parameters.spaceBetween !== "undefined") {
          _sliderParameters.spaceBetween = parameters.spaceBetween;
        }
        if (typeof parameters.breakpoints !== "undefined") {
          _sliderParameters.breakpoints = parameters.breakpoints;
        }
      }

      var slider = new Swiper(id + " .swiper-container", _sliderParameters);
    },
    createMobileOnly: function (id) {
      if ($(id).length > 0) {
        // https://medium.com/@networkaaron/swiper-how-to-destroy-swiper-on-min-width-breakpoints-a947491ddec8
        // var breakpoint = window.matchMedia( '(min-width:62em)' ); //992px
        var breakpoint = window.matchMedia("(min-width:48em)"); //768px

        // keep track of swiper instances to destroy later
        var mySwiper,
          swiperSlide = $(id + " .swiper-slide"),
          nbSlides = swiperSlide.length,
          enableSwiper,
          breakpointChecker = function () {
            // if larger viewport and multi-row layout needed
            if (breakpoint.matches === true) {
              // clean up old instances and inline styles when available
              if (mySwiper !== undefined) {
                // enable swiper if there are more than 5 slides
                if (nbSlides > 3) {
                  return enableSwiper();
                }
                // destroy swiper
                mySwiper.destroy(true, true);
              }
              // add margin if swiper is undefined or disabled
              // swiperSlide.addClass('mr-xl-30');
              // or/and do nothing
              return;
              // else if a small viewport and single column layout needed
            } else if (breakpoint.matches === false) {
              // fire small viewport version of swiper
              return enableSwiper();
            }
          };

        enableSwiper = function () {
          mySwiper = new Swiper(id + " .swiper-container", {
            speed: 800,
            watchOverflow: true,
            watchSlidesVisibility: true,
            slidesPerView: 3,
            spaceBetween: 30,
            pagination: {
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            },
            autoplay: {
              delay: 5000,
            },

            breakpoints: {
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
            },
          });
        };

        // keep an eye on viewport size changes
        breakpoint.addListener(breakpointChecker);
        breakpointChecker();
      }
    },
    initCommonSliders: function () {
      if ($(".categories-slider").length) {
        Slider.create(".categories-slider");
      }

      // Create products slider only if necessary
      if ($(".products-slider").length) {
        var sliderParameters = {
          slidesPerView: 2,
          spaceBetween: 30,
          breakpoints: {
            320: {
              slidesPerView: 2,
            },
            769: {
              slidesPerView: 4,
            },
          },
        };
        Slider.create(".products-slider", sliderParameters);
      }
      // Create ranges slider only if necessary (module)
      if ($(".ranges-gallery-slider").length) {
        var gallerySliderParameters = {
          slidesPerView: 2,
          spaceBetween: 30,
          breakpoints: {
            320: {
              // when window width is >= 320px
              slidesPerView: 1,
              // spaceBetween: 20
            },
            480: {
              // when window width is >= 480px
              slidesPerView: 1,
              // spaceBetween: 30
            },
            640: {
              // when window width is >= 640px
              slidesPerView: 2,
              // spaceBetween: 40
            },
          },
        };
        Slider.create(".ranges-gallery-slider", gallerySliderParameters);
      }
      // Create articles/stores slider only if necessary
      if ($(".articles-or-stores-slider").length) {
        var articlesSliderParameters = {
          slidesPerView: 2,
          spaceBetween: 30,
          breakpoints: {
            320: {
              // when window width is >= 320px
              slidesPerView: 1.2,
              // spaceBetween: 20
            },
            480: {
              // when window width is >= 480px
              slidesPerView: 1.2,
              // spaceBetween: 30
            },
            640: {
              // when window width is >= 640px
              slidesPerView: 3,
              // spaceBetween: 40
            },
          },
        };
        Slider.create(".articles-or-stores-slider", articlesSliderParameters);
      }

      // Create bottles slider only if necessary
      if ($(".bottles-slider").length) {
        var bottlesSliderParameters = {
          slidesPerView: 1,
        };
        Slider.create(".bottles-slider", bottlesSliderParameters);
      }

      // Create bottles slider only if necessary
      if ($(".bottles-slider-desktop").length) {
        var bottlesDesktopSliderParameters = {
          slidesPerView: 1,
        };
        Slider.create(".bottles-slider-desktop", bottlesDesktopSliderParameters);
      }

      if ($(".gammes-slider").length) {
        var gammesSliderParameters = {
          loop: true,
          slidesPerView: 1,

          breakpoints: {
            768: {
              loop: true,
            },

            992: {
              centeredSlides: true,
              grabCursor: true,
              loop: true,
              slidesPerView: 3,
            },
          },
        };
        Slider.create(".gammes-slider", gammesSliderParameters);
      }
    },
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        window.WebFontConfig = {
          typekit: {
            id: "vwq5rwd",
          },
        };
        (function () {
          var wf = document.createElement("script");
          wf.src = "https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js";
          wf.type = "text/javascript";
          wf.async = "true";
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(wf, s);
        })();

        Ajax.init();
        $(".share-button").click(function (e) {
          e.preventDefault();
          var _wrapper = $(".share-wrapper");
          if (_wrapper.hasClass("d-none")) {
            _wrapper.removeClass("d-none");
          } else {
            _wrapper.addClass("d-none");
          }
        });
        // Load Plyr library if there are videos
        if ($(".block-video").length) {
          Fn.videoPlayer();
        }
        // Init masonry list if necessary
        if ($(".masonry-grid").length) {
          setTimeout(function () {
            $(".masonry-grid").masonry({
              columnWidth: ".grid-sizer",
              gutter: ".gutter-sizer",
              itemSelector: ".grid-item",
              percentPosition: true,
            });
          }, 800);
        }
        Slider.initCommonSliders();
        Fn.lastWord();
        Fn.fakeShare();
        Fn.socialShare();
        Fn.menuMask();
        Fn.search();
        Brands.init();
        Castles.init();
        Popup.init();
        PopupShop.init();
        PopupChristmas.init();
        // PopupChristmasShop.init();
        // Cookie mobile
        // $(".load-mobile-cookie").click(function (e) {
        //   e.preventDefault();
        //   $(".navbar-toggler").click();
        //   $("#cookie-law-info-again").click();
        // });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
        Slider.create(".ranges-slider");
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // Article detail
    single_post: {
      init: function () {
        Fn.checkSitckyContent();
      },
    },
    page_template_template_shop: {
      init: function () {
        Fn.checkSitckyContent();
      },
    },
    post_type_archive_product: {
      init: function () {
        Slider.create(".shop-slider");
        if ($(window).width() > 768) {
          Fn.fixedFilters();
        }

        $(window).resize(function () {
          Fn.fixedFilters();
        });

        var sidebar = $(".sidebar-ref"),
          body = $("body");

        $(".load-mobile-filter").click(function (e) {
          e.preventDefault();
          sidebar.addClass("active");
          body.addClass("overflow-hidden");
        });
        $(".validate-filters, .make-me-sticky .close-filters").click(function (
          e
        ) {
          e.preventDefault();
          sidebar.removeClass("active");
          body.removeClass("overflow-hidden");
        });

        //Filters accordion
        $(document).on(
          "click",
          ".wpgb-facet-2 .wpgb-facet-title,.wpgb-facet-3 .wpgb-facet-title,.wpgb-facet-4 .wpgb-facet-title,.wpgb-facet-5 .wpgb-facet-title",
          function (e) {
            if ($(this).next("fieldset").css("display") !== "block") {
              $(".active-facet").slideUp("fast").removeClass("active-facet");
              $(this)
                .next("fieldset")
                .addClass("active-facet")
                .slideDown("slow");
            } else {
              $(".active-facet").slideUp("fast").removeClass("active-facet");
            }
          }
        );
      },
    },
    page_template_template_cepages: {
      init: function () {
        // Go to content from url hash
        var url = window.location.href;
        if (url.indexOf("#") > 0) {
          var _anchor = url.substring(url.indexOf("#") + 1);
          setTimeout(function () {
            $(".grape-varieties-links a[href='#" + _anchor + "']").trigger(
              "click"
            );
          }, 1000);
        }

        $(".grape-varieties-links a").click(function (e) {
          e.preventDefault();
          var _this = $(this),
            _anchor = _this.attr("href"),
            _newUrl = url.split("#")[0] + _anchor;
          $("html, body").animate(
            {
              scrollTop:
                $(_anchor).offset().top - $("header.header").height() - 20,
            },
            1000
          );
          history.replaceState(null, null, _newUrl);
        });
      },
    },
    single_product: {
      init: function () {
        // Download click
        $(".download-button").click(function (e) {
          e.preventDefault();
          var _fileUrl = $(this).attr("href");
          if (_fileUrl) {
            Fn.download_file(_fileUrl, $(this).data("product-name"));
          }
        });
        // Create slider only if product has gallery images
        if ($(".product-gallery-slider").length) {
          Slider.create(".product-gallery-slider");
        }
        Fn.woocommerce_ajax_add_to_cart();
      },
    },
    page_template_template_jobs: {
      init: function () {
        // Fake load more jobs
        $(".jobs-seemore").click(function (e) {
          e.preventDefault();
          $(".jobs")
            .find(".job-card")
            .each(function () {
              $(this).removeClass("d-none");
            });
          $(this).remove();
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    page_template_template_content: {
      init: function () {
        if ($(".module-dates").length) {
          //AOS Init
          AOS.init({
            duration: 500,
            delay: 100,
            disable: "phone",
            once: true,
          });
        }
        Fn.moduleFullWidth();
        // AOS.refresh();
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
